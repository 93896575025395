<template>
  <b-row class="content-section mt-5 py-5">
    <div id="main-content" class="main-page col-xs-12 clearfix container">
      <div id="main" class="site-main clearfix">
        <p>
          This privacy policy has been compiled to better serve those who are
          concerned with how their “Personally Identifiable Information” (PII)
          is being used online. PII is information that can be used on its own
          or with other information to identify, contact, or locate a single
          person, or to identify an individual in context. By visiting
          turtlerent.co, also referred to “our site”, you are accepting the
          practices described in this policy. Please read our privacy policy
          carefully to get a clear understanding of how we collect, use,
          protect or otherwise handle your Personally Identifiable Information
          in accordance with our website and app.
        </p>
        <p>
          <strong>What personal data we collect and why we collect it</strong>
          <br />
          Your Information
          <br />

          You may give us information about you by completing forms on our
          site, app, by allowing us to access your information on social media
          sites or by corresponding with us by phone, e-mail or otherwise.
          This includes information on app login, applying for a loan and
          activity on social media. The information you give us may include
          your name, current and previous address, e-mail, phone number,
          social networks data, financial information, personal status
          information, employment information, educational status, contacts
          and family information, applications and messages.
        </p>
        <p>
          Media
          <br />
          If you upload images to the website, you should avoid uploading
          images with embedded location data (EXIF GPS) included. Visitors to
          the website can download and extract any location data from images
          on the website.
        </p>
        <p>
          Cookies
          <br />
          If you leave a comment on our site you may opt-in to saving your
          name, email address and website in cookies. These are for your
          convenience so that you do not have to fill in your details again
          when you leave another comment. These cookies will last for one
          year.
          <br />
          If you visit our login page, we will set a temporary cookie to
          determine if your browser accepts cookies. This cookie contains no
          personal data and is discarded when you close your browser.
          <br />
          When you log in, we will also set up several cookies to save your
          login information and your screen display choices. Login cookies
          last for two days, and screen options cookies last for a year. If
          you select “Remember Me”, your login will persist for two weeks. If
          you log out of your account, the login cookies will be removed.
          <br />
          If you edit or publish an article, an additional cookie will be
          saved in your browser. This cookie includes no personal data and
          simply indicates the post ID of the article you just edited. It
          expires after 1 day.
          <br />
          We use cookies for tracking purposes only. You can choose to have
          your computer warn you each time a cookie is being sent, or you can
          choose to turn off all cookies. You do this through your browser
          settings. Since browser is a little different, look at your
          browser’s Help Menu to learn the correct way to modify your cookies.
          <br />
          If you turn cookies off, some features will be disabled. That makes
          your site experience more efficient and may not function properly.
        </p>
        <p>
          Embedded content from other websites
          <br />
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites
          behaves in the exact same way as if the visitor has visited the
          other website.
          <br />
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </p>
        <p>
          <strong>How we use your data</strong>
          <br />
          We may use the information we collect from you in the following
          ways:
          <br />
          • To personalize your experience and to allow us to deliver the type
          of content and product offerings in which you are most interested;
          <br />
          • To provide products, services, and information regarding your
          request;
          <br />
          • To create a credit score based on your information;
          <br />
          • To notify about recent changes in the product or services
          provided;
          <br />
          • To follow up with them after correspondence (live chat, email or
          phone inquiries).
        </p>
        <p>
          <strong>How long we retain your data</strong>
          <br />
          If you leave a comment, the comment and its metadata are retained
          indefinitely. This is so we can recognize and approve any follow-up
          comments automatically instead of holding them in a moderation
          queue.
          <br />
          For users that register on our website (if any), we also store the
          personal information they provide in their user profile. All users
          can see, edit, or delete their personal information at any time
          (except they cannot change their username). Website administrators
          can also see and edit that information.
        </p>
        <p>
          <strong>What rights you have over your data</strong>
          <br />
          If you have an account on this site or have left comments, you can
          request to receive an exported file of the personal data we hold
          about you, including any data you have provided to us. You can also
          request that we erase any personal data we hold about you. This does
          not include any data we are obliged to keep for administrative,
          legal, or security purposes.
          <br />
          Where we send your data
          <br />
          Visitor comments may be checked through an automated spam detection
          service. We do not sell, trade, or otherwise transfer to outside
          parties your Personally Identifiable Information unless we provide
          users with advance notice.
          <br />
          Your contact information
          <br />
          We collect information from you when you enter it on our site or our
          app.
        </p>
        <p>
          <strong>Additional information</strong>
          <br />
          When you purchase from us, we’ll ask you to provide information
          including your name, billing address, shipping address, email
          address, phone number, credit card/payment details and optional
          account information like username and password. We’ll use this
          information for purposes, such as, to:
          <br />
          • Send you information about your account and order
          <br />
          • Respond to your requests, including refunds and complaints
          <br />
          • Process payments and prevent fraud
          <br />
          • Set up your account for our store
          <br />
          • Comply with any legal obligations we have, such as calculating
          taxes
          <br />
          • Improve our store offerings
          <br />
          • Send you marketing messages, if you choose to receive them
          <br />
          If you create an account, we will store your name, address, email
          and phone number, which will be used to populate the checkout for
          future orders.
          <br />
          We generally store information about you for as long as we need the
          information for the purposes for which we collect and use it, and we
          are not legally required to continue to keep it. For example, we
          will store order information for 5 years for tax and accounting
          purposes. This includes your name, email address and billing and
          shipping addresses.
          <br />
          We will also store comments or reviews, if you choose to leave them.
        </p>
        <p><strong>Who on our team has access</strong></p>
        <p>
          Members of our team have access to the information you provide us.
          <br />
          • Order information like what was purchased, when it was purchased
          and where it should be sent, and
          <br />
          • Customer information like your name, email address, and billing
          and shipping information.
          <br />
          Our team members have access to this information to help fulfill
          orders, process requests and support you.
        </p>
        <p>
          <strong>How we protect your data</strong>
          <br />
          Your personal information is contained behind secured networks and
          is only accessible by a limited number of persons who have special
          access rights to such systems, and are required to keep the
          information confidential. In addition, all sensitive/credit
          information you supply is encrypted via Secure Socket Layer (SSL)
          technology. We implement a variety of security measures when a user
          asks for a loan, submits data, or accesses their information to
          maintain the safety of your personal information. All transactions
          are processed through a gateway provider and are not stored or
          processed on our servers.
        </p>
        <p>
          <strong>Industry regulatory disclosure requirements</strong>
          <br />
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to
          keep this information confidential. We may also release information
          when it’s release is appropriate to comply with the law, enforce our
          site policies, or protect ours or others’ rights, property or
          safety. However, non-personally identifiable visitor information may
          be provided to other parties for marketing, advertising, or other
          uses.
        </p>
        <p>
          <strong>Contact</strong>
          <br />
          If there are any questions regarding this privacy policy, you may
          contact us using the website form or using the information below.
          <br />
        </p>
      </div>
    </div>
  </b-row>
</template>

<script>
import {
  BRow,
} from 'bootstrap-vue'


export default {
  components: {
    BRow,
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
